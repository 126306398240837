import React from 'react';
import { Link } from 'react-router-dom';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import '../../node_modules/react-lazy-load-image-component/src/effects/blur.css';

import { storage } from '../firebase/firebase';
import { categoryOptions, allNetworks, streamingNetworks, broadcastNetworks } from '../fixtures/formOptions';
import { history } from '../routers/AppRouter';

class EntryForm extends React.Component {
  constructor(props) {
    super(props);
    
    this._isMounted = false;
    this.state = {
      image: '',
      imageURL: '',
      imageUploadProgress: 0,
      validated: false,
      video: '',
      videoURL: '',
      videoUploadProgress: 0,
      paid: false,
      createdOn: props.nomination ? moment(props.nomination.createdOn) : moment(),
      seriesOrTalent: props.nomination ? props.nomination.seriesOrTalent : '',
      streamingOrBroadcast: props.nomination ? props.nomination.streamingOrBroadcast : '',
      category: props.nomination ? props.nomination.category : '',
      entry: props.nomination ? props.nomination.entry : '',
      title: props.nomination ? props.nomination.title : '',
      episodeCount: props.nomination ? props.nomination.episodeCount.toString() : '',
      episodeName: props.nomination ? props.nomination.episodeName : '',
      plotSummary: props.nomination ? props.nomination.plotSummary : '',
      network: props.nomination ? props.nomination.network : '',
      imageFilename: props.nomination ? props.nomination.imageFilename : '',
      screenerLink: props.nomination ? props.nomination.screenerLink : '',
      screenerLinkPass: props.nomination ? props.nomination.screenerLinkPass : '',
      videoFilename: props.nomination ? props.nomination.videoFilename : ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      // IMAGE
      if (history.location.pathname === `/edit-entry/${this.props.entryId}` && this.state.imageFilename !== '' && this.state.seriesOrTalent === 'series') {
        getDownloadURL(ref(storage, this.state.imageFilename)).then((url) => {
          this._isMounted && this.setState(() => ({ imageURL: url }));
        });
      } else if (history.location.pathname === `/edit-entry/${this.props.entryId}` && this.state.imageFilename !== '' && this.state.seriesOrTalent === 'talent') {
        getDownloadURL(ref(storage, this.state.imageFilename)).then((url) => {
          this._isMounted && this.setState(() => ({ imageURL: url }));
        });
      }

      // VIDEO
      if (history.location.pathname === `/edit-entry/${this.props.entryId}` && this.state.videoFilename !== '') {
        getDownloadURL(ref(storage, this.state.videoFilename)).then((url) => {
          this._isMounted && this.setState(() => ({ videoURL: url }));
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  handleCategorySelect = (e) => {
    const formSelectValue = e.target[e.target.selectedIndex].getAttribute('data-form-select');
    const networkSelectValue = e.target[e.target.selectedIndex].getAttribute('data-network-select');
    const category = e.target.value;

    this.setState(() => ({
      entry: '',
      title: '',
      episodeCount: 0,
      episodeName: '',
      plotSummary: '',
      network: '',
      image: '',
      imageFilename: '',
      screenerLink: '',
      screenerLinkPass: '',
      video: '',
      videoFilename: ''
    }));

    if (formSelectValue === 'series' && this.state.seriesOrTalent !== formSelectValue) {
      this.setState(() => ({ seriesOrTalent: formSelectValue }));
    } else if (formSelectValue === 'talent' && this.state.seriesOrTalent !== formSelectValue) {
      this.setState(() => ({ seriesOrTalent: formSelectValue }));
    }
    
    if (networkSelectValue === 'broadcast' && this.state.streamingOrBroadcast !== networkSelectValue) {
      this.setState(() => ({ streamingOrBroadcast: networkSelectValue }));
    } else if (networkSelectValue === 'streaming' && this.state.streamingOrBroadcast !== networkSelectValue) {
      this.setState(() => ({ streamingOrBroadcast: networkSelectValue }));
    } else if (networkSelectValue === 'all' && this.state.streamingOrBroadcast !== networkSelectValue) {
      this.setState(() => ({ streamingOrBroadcast: networkSelectValue }));
    }

    this.setState(() => ({ category }));
  };
  handleEntryValueChange = (e) => {
    const entry = e.target.value;
    this.setState(() => ({ entry }));
  };
  handleTitleValueChange = (e) => {
    const title = e.target.value;
    this.setState(() => ({ title }));
  };
  handleEpisodeCountValueChange = (e) => {
    const episodeCount = e.target.value;
    this.setState(() => ({ episodeCount }));
  };
  handleEpisodeNameValueChange = (e) => {
    const episodeName = e.target.value;
    this.setState(() => ({ episodeName }));
  };
  handlePlotValueChange = (e) => {
    const plotSummary = e.target.value;
    this.setState(() => ({ plotSummary }));
  };
  handleNetworkSelectValueChange = (e) => {
    const network = e.target.value;
    this.setState(() => ({ network }));
  };
  handlePromoImageUpload = (e) => {
    const image = e.target.files[0];
    const imageFilename = e.target.files[0].name;
    if (this.state.imageFilename !== '') { // IF A USER IS ON THE EDIT ENTRY PAGE AND UPLOADED AN IMAGE
      deleteObject(ref(storage, this.state.imageFilename)).then(() => {
        this.setState(() => ({ image }));
        if (this.state.seriesOrTalent === 'series') {
          console.log(imageFilename);
          this.setState(() => ({ imageFilename: 'images/series/' + imageFilename }));
        } else if (this.state.seriesOrTalent === 'talent') {
          console.log(imageFilename);
          this.setState(() => ({ imageFilename: 'images/talent/' + imageFilename }));
        }
      }).catch((error) => {
        console.log(error.code);
      });
    } else {
      this.setState(() => ({ image }));
      if (this.state.seriesOrTalent === 'series') {
        this.setState(() => ({ imageFilename: 'images/series/' + imageFilename }));
      } else if (this.state.seriesOrTalent === 'talent') {
        console.log(imageFilename);
        this.setState(() => ({ imageFilename: 'images/talent/' + imageFilename }));
      }
    }
  };
  handleScreenerLinkValueChange = (e) => {
    const screenerLink = e.target.value;
    this.setState(() => ({ screenerLink }));
  };
  handleScreenerPassValueChange = (e) => {
    const screenerLinkPass = e.target.value;
    this.setState(() => ({ screenerLinkPass }));
  };
  handleScreenerUpload = (e) => {
    const video = e.target.files[0];
    const videoFilename = e.target.files[0].name;
    console.log('EPISODE FILENAME: ', videoFilename);
    if (this.state.videoFilename !== '') { // IF A USER IS ON THE EDIT ENTRY PAGE AND UPLOADED A SCREENER
      deleteObject(ref(storage, this.state.videoFilename)).then(() => {
        this.setState(() => ({ video }));
        this.setState(() => ({ videoFilename: 'screeners/' + videoFilename }));
      }).catch((error) => {
        console.log(error.code);
      });
    } else {
      this.setState(() => ({ video }));
      this.setState(() => ({ videoFilename: 'screeners/' + videoFilename }));
    }
  };
  handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();

      const imageMetadata = {
        contentType: 'image/jpeg'
      };

      if (this.state.image !== '' && this.state.video === '') { // IF A USER UPLOADS AN IMAGE BUT NOT A VIDEO SCREENER
        const storageRef = ref(storage, this.state.imageFilename);
        const uploadTask = uploadBytesResumable(storageRef, this.state.image, imageMetadata);

        uploadTask.on('state_changed', (snapshot) => {
          let imageUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + imageUploadProgress + '% done');
          this.setState(() => ({ imageUploadProgress }));
        }, (error) => {
          console.log(error.code);
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }, () => {
          this.props.handleSubmit({
            createdOn: this.state.createdOn.valueOf(),
            seriesOrTalent: this.state.seriesOrTalent,
            streamingOrBroadcast: this.state.streamingOrBroadcast,
            paid: false,
            category: this.state.category,
            entry: this.state.entry,
            title: this.state.title,
            episodeCount: parseFloat(this.state.episodeCount, 10),
            episodeName: this.state.episodeName,
            plotSummary: this.state.plotSummary,
            network: this.state.network,
            imageFilename: this.state.imageFilename,
            screenerLink: this.state.screenerLink,
            screenerLinkPass: this.state.screenerLinkPass,
            videoFilename: this.state.videoFilename
          });
        });
      } else if (this.state.image === '' && this.state.video !== '') { // IF A USER UPLOADS A VIDEO SCREENER BUT NOT AN IMAGE
        const storageRef = ref(storage, this.state.videoFilename);
        const uploadTask = uploadBytesResumable(storageRef, this.state.video);

        uploadTask.on('state_changed', (snapshot) => {
          let videoUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + videoUploadProgress + '% done');
          this.setState(() => ({ videoUploadProgress }));
        }, (error) => {
          console.log(error.code);
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }, () => {
          this.props.handleSubmit({
            createdOn: this.state.createdOn.valueOf(),
            seriesOrTalent: this.state.seriesOrTalent,
            streamingOrBroadcast: this.state.streamingOrBroadcast,
            paid: false,
            category: this.state.category,
            entry: this.state.entry,
            title: this.state.title,
            episodeCount: parseFloat(this.state.episodeCount, 10),
            episodeName: this.state.episodeName,
            plotSummary: this.state.plotSummary,
            network: this.state.network,
            imageFilename: this.state.imageFilename,
            screenerLink: this.state.screenerLink,
            screenerLinkPass: this.state.screenerLinkPass,
            videoFilename: this.state.videoFilename
          });
        });
      } else if (this.state.image !== '' && this.state.video !== '') { // IF A USER UPLOADS AN IMAGE AND A VIDEO SCREENER
        const imageRef = ref(storage, this.state.imageFilename);
        const videoRef = ref(storage, this.state.videoFilename);
        const imgUploadTask = uploadBytesResumable(imageRef, this.state.image, imageMetadata);
        const vidUploadTask = uploadBytesResumable(videoRef, this.state.video);

        imgUploadTask.on('state_changed', (snapshot) => {
          let imageUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + imageUploadProgress + '% done');
          this.setState(() => ({ imageUploadProgress }));
        }, (error) => {
          console.log(error.code);
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        });

        vidUploadTask.on('state_changed', (snapshot) => {
          let videoUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + videoUploadProgress + '% done');
          this.setState(() => ({ videoUploadProgress }));
        }, (error) => {
          console.log(error.code);
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }, () => {
          this.props.handleSubmit({
            createdOn: this.state.createdOn.valueOf(),
            seriesOrTalent: this.state.seriesOrTalent,
            streamingOrBroadcast: this.state.streamingOrBroadcast,
            paid: false,
            category: this.state.category,
            entry: this.state.entry,
            title: this.state.title,
            episodeCount: parseFloat(this.state.episodeCount, 10),
            episodeName: this.state.episodeName,
            plotSummary: this.state.plotSummary,
            network: this.state.network,
            imageFilename: this.state.imageFilename,
            screenerLink: this.state.screenerLink,
            screenerLinkPass: this.state.screenerLinkPass,
            videoFilename: this.state.videoFilename
          });
        });
      } else {
        this.props.handleSubmit({
          createdOn: this.state.createdOn.valueOf(),
          seriesOrTalent: this.state.seriesOrTalent,
          streamingOrBroadcast: this.state.streamingOrBroadcast,
          paid: false,
          category: this.state.category,
          entry: this.state.entry,
          title: this.state.title,
          episodeCount: parseFloat(this.state.episodeCount, 10),
          episodeName: this.state.episodeName,
          plotSummary: this.state.plotSummary,
          network: this.state.network,
          imageFilename: this.state.imageFilename,
          screenerLink: this.state.screenerLink,
          screenerLinkPass: this.state.screenerLinkPass,
          videoFilename: this.state.videoFilename
        });
      };
    }
    this.setState(() => ({ validated: true }));
  };

  render() {
    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
        <Row className='justify-content-center'>
          <Col>
            <Form.Select 
              className='mb-3' 
              defaultValue={this.state.category} 
              id='categorySelectInput'
              onChange={this.handleCategorySelect}
            >
              <option value='' hidden>SELECT AN AWARD CATEGORY</option>
              {categoryOptions.map(({ category, formSelect, networkSelect, disabled }, i) => <option value={category} data-form-select={formSelect} data-network-select={networkSelect} disabled={disabled} key={i}>{category}</option>)}
            </Form.Select>
          </Col>
        </Row>
        {
          this.state.seriesOrTalent === 'series' && 
          (
            <div id='form--series'>
              <Row className='justify-content-center'>
                <Form.Group as={Col} lg={7} md={12} controlId='formEntry'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    className='mb-3'
                    onChange={this.handleEntryValueChange}
                    placeholder='ex. Schoolhouse Rock'
                    required
                    type='text'
                    value={this.state.entry}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a show title.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={5} md={12} controlId='formEpisodes'>
                  <Form.Label>Number of Episodes from June 1, 2022 to May 31, 2024</Form.Label>
                  <Form.Control
                    className='mb-3'
                    onChange={this.handleEpisodeCountValueChange}
                    placeholder='If entering a movie or standup special, then type "0"'
                    required
                    type='number'
                    value={this.state.episodeCount}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='justify-content-center'>
                <Form.Group as={Col} controlId='formEntry'>
                  <Form.Label>Plot Summary</Form.Label>
                  <Form.Control
                    as='textarea'
                    className='mb-3'
                    defaultValue={this.state.plotSummary}
                    onChange={this.handlePlotValueChange}
                    required
                    rows={10}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a synopsis.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='justify-content-center'>
                <Col>
                  <Form.Select
                    className='mb-3'
                    id='inputSelectNetwork'
                    onChange={this.handleNetworkSelectValueChange} 
                    required
                    value={this.state.network}
                  >
                    <option value='' hidden>SELECT A NETWORK</option>
                    {this.state.streamingOrBroadcast === 'broadcast' && broadcastNetworks.map(({ network, disabled }, i) => <option value={network} key={i} disabled={disabled}>{network}</option>)};
                    {this.state.streamingOrBroadcast === 'streaming' && streamingNetworks.map((network, i) => <option value={network} key={i}>{network}</option>)};
                    {this.state.streamingOrBroadcast === 'all' && allNetworks.map(({ network, disabled }, i) => <option value={network} key={i} disabled={disabled}>{network}</option>)};
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    Please enter a network.
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className='mb-5 justify-content-center'>
                <Form.Group as={Col} className='mb-3' controlId='formImage' md={12}>
                  <Form.Label>Vertical Poster</Form.Label>
                  <Row>
                    {history.location.pathname !== `/edit-entry/${this.props.entryId}` ? (
                      <Col>
                        <Form.Control 
                          className='mb-3' 
                          onChange={this.handlePromoImageUpload} 
                          type='file' 
                        />
                      </Col>
                    ) : (
                      <>
                        {this.state.imageFilename !== '' && 
                          <Col sm={12} className='mb-3'>
                            <LazyLoadImage 
                              alt='Promotional Image'
                              className='w-100' 
                              effect='blur'
                              src={this.state.imageURL} 
                            />
                          </Col>
                        }
                        {this.state.imageFilename !== '' ? (
                          <Col sm={12}>
                            <Form.Control 
                              className='mb-3'
                              onChange={this.handlePromoImageUpload} 
                              type='file' 
                            />
                          </Col>
                        ) : (
                          <Col>
                            <Form.Control 
                              className='mb-3' 
                              onChange={this.handlePromoImageUpload} 
                              type='file' 
                            />
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                  <ProgressBar animated now={this.state.imageUploadProgress} />
                </Form.Group>
              </Row>
              <h1>Video Information</h1>
              <div className='entry-form--video-information mb-5'>
                Video submission of a series, reality series, talk show, limited series, docuseries, or television movie for consideration. Three ways to submit videos:
                <ul>
                  <li>Provide a digital link to access the screener.</li>
                  <li>Provide an FYC app or website with all required login information.</li>
                  <li>Upload the video and share it to <a className='guidelines--link' href='mailto:submissions@hollywoodcriticsassociation.com'>Submissions@HollywoodCriticsAssociation.com</a> using WeTransfer, Dropbox, or Google Drive.</li>
                  <li>Video Quality: 720p, 1080p, or 4K.</li>
                  <li>Video Format: MP4 or MOV.</li>
                  <li>Audio Codec: Stereo or 5.1 Sound.</li>
                  <li>The file size should be under 10GB.</li>
                  <li>The file name should include the name of the movie or series, episode number, season number, and the qualifying category being submitted for.</li>
                  <li>All video submissions should include beginning and end credits.</li>
                  <li>Closed Captioning is preferred.</li>
                </ul>
              </div>
              <Row className='justify-content-center'>
                <Form.Group as={Col} md={6} sm={12} controlId='formScreenerLink'>
                  <Form.Label>Screener Link</Form.Label>
                  <Form.Control
                    className='mb-3'
                    onChange={this.handleScreenerLinkValueChange}
                    type='text'
                    value={this.state.screenerLink}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12} controlId='formScreenerPass'>
                  <Form.Label>Password for Screener Link</Form.Label>
                  <Form.Control
                    className='mb-3' 
                    onChange={this.handleScreenerPassValueChange}
                    type='text'
                    value={this.state.screenerLinkPass}
                  />
                </Form.Group>
              </Row>
              <Row className='mb-5 justify-content-center'>
                <Form.Group as={Col} controlId='formImage' md={12}>
                  <Form.Label>Screener Upload</Form.Label>
                  <Row>
                    {history.location.pathname !== `/edit-entry/${this.props.entryId}` ? (
                      <Col>
                        <Form.Control 
                          className='mb-3' 
                          onChange={this.handleScreenerUpload} 
                          type='file' 
                        />
                      </Col>
                    ) : (
                      <>
                        {this.state.videoFilename !== '' && 
                          <Col md={12} className='mb-3'>
                            <video className='form--video-preview' controls src={this.state.videoURL} width="640"></video>
                          </Col>
                        }
                        <Col md={12}>
                          <Form.Control 
                            className='mb-3' 
                            onChange={this.handleScreenerUpload} 
                            type='file' 
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                  <ProgressBar animated now={this.state.videoUploadProgress} />
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  {
                    history.location.pathname === `/edit-entry/${this.props.entryId}` 
                    ? 
                    <Button className='me-2' type='submit' variant='warning'>Submit Entry</Button> 
                    : 
                    <Button type='submit' variant='warning'>Submit Entry</Button>
                  }
                  {
                    history.location.pathname === `/edit-entry/${this.props.entryId}` && 
                    <Link to='/dashboard'><Button type='button' variant='warning'>Cancel</Button></Link>
                  }
                </Col>
              </Row>
            </div>
          )
        }
        {
          this.state.seriesOrTalent === 'talent' && 
          (
            <div id='form--talent'>
              <Row className='justify-content-center'>
                <Form.Group as={Col} md={6} sm={12} controlId='formEntry'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control 
                    className='mb-3'
                    onChange={this.handleEntryValueChange} 
                    placeholder='ex. Dominic L. Ement' 
                    required
                    type='text' 
                    value={this.state.entry}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12} controlId='formTitle'>
                  <Form.Label>Film or Series Title</Form.Label>
                  <Form.Control 
                    className='mb-3'
                    onChange={this.handleTitleValueChange} 
                    placeholder='ex. Boy Meets World' 
                    required 
                    type='text' 
                    value={this.state.title} 
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a title.
                  </Form.Control.Feedback>
                </Form.Group>
                {/* <Form.Group as={Col} md={4} sm={12} controlId='formEpisodeName'>
                  <Form.Label>Episode Name</Form.Label>
                  <Form.Control 
                    className='mb-3'
                    onChange={this.handleEpisodeNameValueChange} 
                    placeholder='ex. The Great Escape' 
                    required
                    type='text' 
                    value={this.state.episodeName} 
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter an episode name.
                  </Form.Control.Feedback>
                </Form.Group> */}
              </Row>
              <Row className='justify-content-center'>
                <Col>
                  <Form.Select 
                    className='mb-3'
                    defaultValue={this.state.network} 
                    onChange={this.handleNetworkSelectValueChange} 
                    required
                  >
                    <option value='' hidden>SELECT A STUDIO OR NETWORK</option>
                    {this.state.streamingOrBroadcast === 'broadcast' && broadcastNetworks.map(({ network, disabled }, i) => <option value={network} key={i} disabled={disabled}>{network}</option>)};
                    {this.state.streamingOrBroadcast === 'streaming' && streamingNetworks.map((network, i) => <option value={network} key={i}>{network}</option>)};
                    {this.state.streamingOrBroadcast === 'all' && allNetworks.map(({ network, disabled }, i) => <option value={network} key={i} disabled={disabled}>{network}</option>)};
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    Please enter a network.
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className='mb-5 justify-content-center'>
                <Form.Group as={Col} className='mb-3' controlId='formImage' md={12}>
                  <Form.Label>Headshot or Promotional Image</Form.Label>
                  <Row>
                    {history.location.pathname !== `/edit-entry/${this.props.entryId}` ? (
                      <Col>
                        <Form.Control 
                          className='mb-3' 
                          onChange={this.handlePromoImageUpload} 
                          type='file' 
                        />
                      </Col>
                    ) : (
                      <>
                        {this.state.imageFilename !== '' && 
                          <Col sm={12} className='mb-3'>
                            <LazyLoadImage 
                              alt='Promotional Image'
                              className='w-100' 
                              effect='blur'
                              src={this.state.imageURL} 
                            />
                          </Col>
                        }
                        {this.state.imageFilename !== '' ? (
                          <Col sm={12}>
                            <Form.Control 
                              className='mb-3' 
                              onChange={this.handlePromoImageUpload} 
                              type='file' 
                            />
                          </Col>
                         ) : (
                          <Col>
                            <Form.Control 
                              className='mb-3' 
                              onChange={this.handlePromoImageUpload} 
                              type='file' 
                            />
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                  <ProgressBar animated now={this.state.imageUploadProgress} />
                </Form.Group>
              </Row>
              <h1>Video Information</h1>
              <div className='entry-form--video-information mb-5'>
                {/* Video submission of a series, reality series, talk show, limited series, docuseries, or television movie for consideration. Three ways to submit videos: */}
                <ul>
                  <li>Provide a digital link to access the screener.</li>
                  <li>Provide an FYC app or website with all required login information.</li>
                  <li>Upload the video and share it to <a className='guidelines--link' href='mailto:submissions@hollywoodcreative.org'>Submissions@HollywoodCreative.org</a> using WeTransfer, Dropbox, or Google Drive.</li>
                  <li>Video Quality: 720p, 1080p, or 4K.</li>
                  <li>Video Format: MP4 or MOV.</li>
                  <li>Audio Codec: Stereo or 5.1 Sound.</li>
                  <li>The file size should be under 35GB.</li>
                  <li>The file name should include the name of the movie or series.</li>
                  <li>All video submissions should include beginning and end credits.</li>
                  <li>Closed Captioning is preferred.</li>
                </ul>
              </div>
              <Row className='justify-content-center'>
                <Form.Group as={Col} md={6} sm={12} controlId='formScreenerLink'>
                  <Form.Label>Screener Link</Form.Label>
                  <Form.Control
                    className='mb-3'
                    onChange={this.handleScreenerLinkValueChange}
                    type='text'
                    value={this.state.screenerLink}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12} controlId='formScreenerPass'>
                  <Form.Label>Password for Screener Link</Form.Label>
                  <Form.Control
                    className='mb-3'
                    onChange={this.handleScreenerPassValueChange}
                    type='text'
                    value={this.state.screenerLinkPass}
                  />
                </Form.Group>
              </Row>
              {/* <Row className='justify-content-center'>
                <Form.Group as={Col} className='mb-3' controlId='formImage' md={12}>
                  <Form.Label>Screener Upload</Form.Label>
                  <Row>
                    {history.location.pathname !== `/edit-entry/${this.props.entryId}` ? (
                      <Col>
                        <Form.Control 
                          className='mb-3' 
                          onChange={this.handleScreenerUpload} 
                          type='file' 
                        />
                      </Col>
                    ) : (
                      <>
                        {this.state.videoFilename !== '' && 
                          <Col className='mb-3'>
                            <video className='form--video-preview' controls src={this.state.videoURL} width="640"></video>
                          </Col>
                        }
                        <Col md={12}>
                          <Form.Control 
                            className='mb-3' 
                            onChange={this.handleScreenerUpload} 
                            type='file' 
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                  <ProgressBar animated now={this.state.videoUploadProgress} />
                </Form.Group>
              </Row> */}
              <Row>
                <Col>
                  {
                    history.location.pathname === `/edit-entry/${this.props.entryId}` 
                    ? 
                    <Button className='me-2' type='submit' variant='warning'>Submit Entry</Button> 
                    : 
                    <Button type='submit' variant='warning'>Submit Entry</Button>
                  }
                  {
                    history.location.pathname === `/edit-entry/${this.props.entryId}` && 
                    <Link to='/dashboard'><Button type='button' variant='warning'>Cancel</Button></Link>
                  }
                </Col>
              </Row>
            </div>
          )
        }
      </Form>
    )
  }
}

export default EntryForm;