import { child, get, push, ref, set, update } from "firebase/database";
import { deleteObject, ref as storageRef } from "firebase/storage";
import db, { storage } from '../firebase/firebase';
import { history } from '../routers/AppRouter';

// ADD NOMINATION ENTRY
export const addNomination = (nomination) => ({
  type: 'ADD_TV_NOMINATION',
  nomination
});

export const startAddNomination = (nominationData = {}) => {
  return (dispatch, getState) => {
    const currentUid = getState().auth.uid;
    const {
      uid = currentUid,
      createdOn = 0,
      seriesOrTalent = 'talent',
      streamingOrBroadcast = '',
      paid = false,
      inCart = true,
      category = '',
      entry = '',
      title = '',
      episodeCount = 0,
      episodeName = '',
      plotSummary = '',
      network = '',
      imageFilename = '',
      screenerLink = '',
      screenerLinkPass = '',
      videoFilename = ''
    } = nominationData;
    const nomination = {
      uid,
      createdOn,
      seriesOrTalent,
      streamingOrBroadcast,
      paid,
      inCart,
      category,
      entry,
      title,
      episodeCount,
      episodeName,
      plotSummary,
      network,
      imageFilename,
      screenerLink,
      screenerLinkPass,
      videoFilename
    }
    
    return push(child(ref(db), `users/${uid}/nomination`), nomination).then((ref) => {
      dispatch(addNomination({
        id: ref.key,
        ...nomination
      }))
      history.push(`/create-entry-summary/${ref.key}`);
    });
  }
};

// REMOVE NOMINATION ENTRY
export const removeNomination = ({ id } = {}) => ({
  type: 'REMOVE_TV_NOMINATION',
  id
});

export const startRemoveNomination = ({ id, imageFilename, videoFilename } = {}) => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    return set(ref(db, `users/${uid}/nomination/${id}`), null).then(() => {
      if (imageFilename !== '' && videoFilename === '') {
        deleteObject(storageRef(storage, imageFilename)).then(() => {
          dispatch(removeNomination({ id }));
          history.push('/dashboard');
        }).catch((error) => {
          console.log(error.code);
        });
      } else if (imageFilename === '' && videoFilename !== '') {
        deleteObject(storageRef(storage, videoFilename)).then(() => {
          dispatch(removeNomination({ id }));
          history.push('/dashboard');
        }).catch((error) => {
          console.log(error.code);
        });
      } else if (imageFilename !== '' && videoFilename !== '') {
        deleteObject(storageRef(storage, imageFilename)).then(() => {
          // DON'T DO ANYTHING BECAUSE VIDEO DELETION
          // WILL TAKE CARE OF THE ACTIONS
        }).catch((error) => {
          console.log(error.code);
        });

        deleteObject(storageRef(storage, videoFilename)).then(() => {
          dispatch(removeNomination({ id }));
          history.push('/dashboard');
        }).catch((error) => {
          console.log(error.code);
        });
      } else if (imageFilename === '' && videoFilename === '') {
        dispatch(removeNomination({ id }));
        history.push('/dashboard');
      }
    });
  }
};

// EDIT NOMINATION ENTRY
export const editNomination = (id, updates) => ({
  type: 'EDIT_TV_NOMINATION',
  id,
  updates
});

export const startEditNomination = (id, updates) => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    return update(ref(db, `users/${uid}/nomination/${id}`), updates).then(() => {
      dispatch(editNomination(id, updates));
    });
  }
}

// MARK AS PAID
export const markAsPaid = (id, updates) => ({
  type: 'MARK_AS_PAID',
  id,
  updates
});

export const startMarkAsPaid = (id, updates) => {
  return (dispatch, getState) => {
    const updateData = {
      ...updates,
      paid: true
    }
    const uid = updates.uid;
    return update(ref(db, `users/${uid}/nomination/${id}`), updateData).then(() => {
      dispatch(markAsPaid(id, updateData));
    });
  }
}

// SET NOMINATIONS
export const setNominations = (nominations) => ({
  type: 'SET_TV_NOMINATIONS',
  nominations
});

export const startSetNominations = () => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    const dbRef = ref(db);
    return get(child(dbRef, `users/${uid}/nomination`)).then((snapshot) => {
      if (snapshot.exists()) {
        const nominations = []
        snapshot.forEach((childSnapshot) => {
          nominations.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });
        dispatch(setNominations(nominations))
      } else {
        console.log('NO DATA AVAILABLE');
      }
    }).catch((error) => {
      console.error(error);
    });
  }
};

// SET ADMIN NOMINATIONS LIST
export const setAdminNominations = (entries) => ({
  type: 'SET_ADMIN_NOMINATIONS',
  entries
});

export const startSetAdminNominations = () => {
  return (dispatch, getState) => {
    const dbRef = ref(db);
    return get(dbRef, 'users/').then((snapshot) => {
      let entries = [];
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          Object.entries(childSnapshot.val()).forEach((nomination) => {
            const [key, value] = nomination;
            Object.entries(value.nomination).forEach((entry) => {
              const [key, value] = entry;
              entries.push({
                entryId: key,
                ...value
              });
            });
          });
        });
        dispatch(setAdminNominations(entries));
      } else {
        console.log('NO DATA FOUND');
      }
    });
  }
}