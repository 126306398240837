export const categoryOptions = [
{
  category: '-- CREATIVE ARTS CEREMONY CATEGORY - FILM --', // BROADCAST/CABLE SERIES
  formSelect: null,
  networkSelect: 'broadcast',
  disabled: true
},
{
	category: 'Best Casting',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Cinematography',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Costume Design',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Editing',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Makeup & Hairstyling',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Original Song',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Production Design',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Marketing Campaign',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Score',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Second Unit Director',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Sound',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Stunts',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Stunt Coordinator',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Stunt Performer',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
	category: 'Best Visual Effects',
	formSelect: 'talent',
	networkSelect: 'broadcast',
	disabled: false
},
{
  category: '-- CREATIVE ARTS CEREMONY CATEGORY - TV --', // CREATIVE ARTS SERIES
  formSelect: null,
  networkSelect: 'all',
  disabled: false
},
{
	category: 'Best Casting',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Choreography',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Cinematography',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Costume Design',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Editing',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Hairstyling',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Main Title Design',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Makeup',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Original Song',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Production Design',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Score',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Sound',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Stunts',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Visual Effects',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
}
];

//To be listed under TV Category
export const allNetworks = [
  {
     network: '-- BROADCAST NETWORKS --',
     disabled: true
  },
  {
    network: 'ABC',
    disabled: false
  },
  {
    network: 'CBS',
    disabled: false
  },
  {
    network: 'FOX',
    disabled: false
  },
  {
    network: 'NBC',
    disabled: false
  },
  {
    network: 'PBS',
    disabled: false
  },
  {
    network: 'The CW',
    disabled: false
  },
  {
    network: '-- CABLE NETWORKS --',
    disabled: true
 },
  {
    network: 'A&E',
    disabled: false
  },
  {
    network: 'AMC',
    disabled: false
  },
  {
    network: 'BBC',
    disabled: false
  },
  {
    network: 'BET',
    disabled: false
  },
  {
    network: 'Bravo',
    disabled: false
  },
  {
    network: 'Discovery Channel',
    disabled: false
  },
  {
    network: 'ESPN',
    disabled: false
  },
  {
    network: 'Food Network',
    disabled: false
  },
  {
    network: 'Freeform',
    disabled: false
  },
  {
    network: 'FX',
    disabled: false
  },
  {
    network: 'Hallmark',
    disabled: false
  },
  {
    network: 'HBO',
    disabled: false
  },
  {
    network: 'Lifetime',
    disabled: false
  },
  {
    network: 'MTV',
    disabled: false
  },
  {
    network: 'National Geographic',
    disabled: false
  },
  {
    network: 'Other.',
    disabled: false
  },
  {
    network: 'OWN',
    disabled: false
  },
  {
    network: 'Paramount Network',
    disabled: false
  },
  {
    network: 'SYFY',
    disabled: false
  },
  {
    network: 'Starz',
    disabled: false
  },
  {
    network: 'The History Channel',
    disabled: false
  },
  {
    network: 'TNT',
    disabled: false
  },
  {
    network: 'USA',
    disabled: false
  },
  {
    network: 'VH1',
    disabled: false
  },
  {
    network: '-- STREAMING PLATFORMS --',
    disabled: true
 },
 {
  network: 'Apple TV+',
  disabled: false
},
{
  network: 'BET+',
  disabled: false
},
{
  network: 'Disney+',
  disabled: false
},
{
  network: 'FX on Hulu',
  disabled: false
},
{
  network: 'Hulu',
  disabled: false
},
{
  network: 'Max',
  disabled: false
},
{
  network: 'MGM+',
  disabled: false
},
{
  network: 'Netflix',
  disabled: false
},
{
  network: 'Paramount+',
  disabled: false
},
{
  network: 'Peacock',
  disabled: false
},
{
  network: 'Pluto TV',
  disabled: false
},
{
  network: 'Prime Video',
  disabled: false
},
{
  network: 'Shudder',
  disabled: false
},
{
  network: 'The Roku Channel',
  disabled: false
},
{
  network: 'Tubi',
  disabled: false
},
{
  network: 'YouTube',
  disabled: false
}
];

export const streamingNetworks = [
  'Acorn TV',
  'ALLBLK',
  'Amazon Prime',
  'AMC+',
  'Apple TV+',
  'BET+',
  'Crunchyroll',
  'Discovery+',
  'Disney+',
  'ESPN+',
  'Facebook Watch',
  'Freevee',
  'HBO Max',
  'Hulu',
  'IMDb',
  'Netflix',
  'Pantaya',
  'Paramount+',
  'Peacock',
  'Pluto TV',
  'Roku',
  'Shudder',
  'Sundance Now',
  'WE tv+',
  'YouTube'
];

// To be listed under Film Category 
export const broadcastNetworks = [
  {
    network: '-- FILM STUDIO NETWORKS --',
    disabled: true
  },
  {
    network: '20th Century Studios',
    disabled: false
  },
  {
    network: 'A24',
    disabled: false
  },
  {
    network: 'Apple TV+',
    disabled: false
  },
  {
    network: 'Amazon MGM Studios',
    disabled: false
  },
  {
    network: 'Focus Features',
    disabled: false
  },
  {
    network: 'IFC Films',
    disabled: false
  }, 
  {
    network: 'Lionsgate',
    disabled: false
  },
  {
    network: 'Magnolia Pictures',
    disabled: false
  },
  {
    network: 'Mubi',
    disabled: false
  },
  {
    network: 'Netflix',
    disabled: false
  },
  {
    network: 'Neon',
    disabled: false
  },
  {
    network: 'Orion Pictures',
    disabled: false
  },
  {
    network: 'Other',
    disabled: false
  },
  {
    network: 'Paramount Pictures',
    disabled: false
  },
  {
    network: 'Pixar',
    disabled: false
  },
  {
    network: 'Searchlight Pictures',
    disabled: false
  },
  {
    network: 'Sony Pictures Classics',
    disabled: false
  },
  {
    network: 'Sony Pictures',
    disabled: false
  },
  {
    network: 'Universal Pictures',
    disabled: false
  },
  {
    network: 'Utopia',
    disabled: false
  },
  {
    network: 'Walt Disney Pictures',
    disabled: false
  },
  {
    network: 'Warner Bros. Pictures',
    disabled: false
  }
];