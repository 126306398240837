import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { onValue, ref, update } from 'firebase/database';
import regeneratorRuntime from 'regenerator-runtime';
import Spinner from 'react-bootstrap/Spinner';
import db, { auth } from '../firebase/firebase';
import { history } from '../routers/AppRouter';

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: 'http://tvsubmissions.hollywoodcriticsassociation.com/payment-complete',
        //return_url: 'https://hcacreativeartssubmissions.hollywoodcreative.org/payment-complete',
        return_url: `${window.location.origin}/payment-complete`,
      },
      redirect: 'if_required'
    }).then((result) => {
      console.log(result.paymentIntent.status);
      if (result.paymentIntent.status === 'succeeded') {
        const uid = auth.currentUser.uid;
        const dbRef = ref(db, `users/${uid}/nomination`);
        onValue(dbRef, (snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((childSnapshot) => {
              if (childSnapshot.val().inCart && childSnapshot.val().paid === false) {
                const id = childSnapshot.key;
                const updates = {
                  category: childSnapshot.val().category,
                  createdOn: childSnapshot.val().createdOn,
                  entry: childSnapshot.val().entry,
                  episodeCount: childSnapshot.val().episodeCount,
                  episodeName: childSnapshot.val().episodeName,
                  imageFilename: childSnapshot.val().imageFilename,
                  network: childSnapshot.val().network,
                  paid: true,
                  plotSummary: childSnapshot.val().plotSummary,
                  screenerLink: childSnapshot.val().screenerLink,
                  screenerLinkPass: childSnapshot.val().screenerLinkPass,
                  seriesOrTalent: childSnapshot.val().seriesOrTalent,
                  streamingOrBroadcast: childSnapshot.val().streamingOrBroadcast,
                  title: childSnapshot.val().title,
                  videoFilename: childSnapshot.val().videoFilename
                }
                console.log(updates);
                update(ref(db, `users/${uid}/nomination/${id}`), updates).then(() => {
                  // window.location.replace('https://hca-tv-awards-dev.herokuapp.com/payment-complete'); // DEV
                  // window.location.replace('https://hcacreativeartssubmissions.hollywoodcreative.org/payment-complete'); // MAIN
                 window.location.replace(`${window.location.origin}/payment-complete`);
                });
              }
            });
          }
        });
      }
    }).catch((error) => {
      console.log(error);
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage('Your payment has been declined.');
      }
    });

    setIsLoading(false);
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <PaymentElement id='payment-element' />
      <button className='btn btn-warning mt-4' disabled={isLoading || !stripe || !elements} id='submit'>
        <span id='button-text'>
          {isLoading ? <Spinner animation="border" variant="light" /> : 'Pay now'}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id='payment-message'>{message}</div>}
    </form>
  );
}