import React from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Layout from '../components/Layout';

const SubmissionGuidelinesPage = () => (
  <Layout pageId='submission-guidelines' pageTitle='SUBMISSION GUIDELINES'>
    <Row>
      <Col md={{ span: 10, offset: 1 }} className='main--guidelines flex-column'>
        <h3 className='mb-3'>Submission Guidelines:</h3>
        {/* <p><strong>December 31, 2024</strong></p> */}
        <p>Astra Creative Arts Submissions are now open.</p>
        <p>Any film or television show released between January 1, 2024 and December 31, 2024 is eligible.</p>

        <p>Each winning entry will receive a single statuette.</p>

        <p>*Additional statuettes can be ordered by the studio, network, or winner's request for a flat fee of $250 per statue.</p>

        <p>Order Form: <a className='guidelines--link' href='https://buy.stripe.com/7sI02b21jg3jbBu28i'>https://buy.stripe.com/7sI02b21jg3jbBu28i</a></p>

        <h3 className='mt-5 mb-3'>Entry Fees:</h3>

        <p>$100 <strong>per individual</strong> in all Creative Arts categories.</p>

        <p>All entry fees must be paid by November 10, 2024.</p>
        
        <h3 className='mt-5 mb-3'>Film Creative Arts Awards Categories:</h3>
        <ul className='list-unstyled'>
          <li>Best Casting</li>
          <li>Best Cinematography</li>
          <li>Best Costume Design</li>
          <li>Best Editing</li>
          <li>Best Makeup & Hairstyling</li>
          <li>Best Original Song</li>
          <li>Best Production Design</li>
          <li>Best Marketing Campaign</li>
          <li>Best Score</li>
          <li>Best Second Unit Director</li>
          <li>Best Sound</li>
          <li>Best Stunts</li>
          <li>Best Stunt Coordinator</li>
          <li>Best Stunt Performer</li>
          <li>Best Visual Effects</li>
        </ul>

        <h3 className='mt-5 mb-3'>TV Creative Arts Awards Categories:</h3>
        <ul className='list-unstyled'>
          <li>Best Casting</li>
          <li>Best Choreography</li>
          <li>Best Cinematography</li>
          <li>Best Costume Design</li>
          <li>Best Editing</li>
          <li>Best Hairstyling</li>
          <li>Best Main Title Design</li>
          <li>Best Makeup</li>          
          <li>Best Original Song</li>
          <li>Best Production Design</li>
          <li>Best Score</li>
          <li>Best Sound</li>
          <li>Best Stunts</li>
          <li>Best Visual Effects</li>
        </ul>

        <p>There will be five nominees in each category. Nominees will be selected by the Astra Creative Arts Nominating Committee. All members will be eligible to vote for the winners.</p>
        
        <h3 className='mt-5 mb-3'>Astra Creative Arts Awards Timeline:</h3>
        <ul className='list-unstyled'>
          <li><strong>Monday, September 23, 2024</strong> - Astra Creative Arts Awards Submissions Open</li>
          <li><strong>Monday, October 7, 2024</strong> - Astra Creative Arts Awards Nominating Committee Applications Open</li>
          <li><strong>Wednesday, October 16, 2024</strong> - Astra Creative Arts Awards Nomination Committee Applications Close</li>
          <li><strong>Monday, November 11, 2024</strong> - Astra Creative Arts Awards Submissions Close</li>
          <li><strong>Tuesday, November 12, 2024</strong> - Astra Creative Arts Nominating Committee Voting Begins at 8:59 am PDT</li>
          <li><strong>Tuesday, November 19, 2024</strong> - Astra Creative Arts Nominating Committee Voting Ends at 8:59 pm PDT</li>
          <li><strong>Monday, November 25, 2024</strong> - Astra Creative Arts & Film Nomination Announcement at 8:30 am PT</li>
          <li><strong>Sunday, December 8, 2024</strong> - TBD - Awards Ceremony</li>
        </ul>

        {/* <h3 className='mt-5 mb-3'>2024 HCA TV Awards Broadcast/Cable Ceremony Categories:</h3>
        <ul className='list-unstyled'>
          <li>Best Broadcast Network or Cable Animated Series or Television Movie</li>
          <li>Best Broadcast Network Reality Show or Competition Series</li>
          <li>Best Cable Reality Show or Competition Series</li>
          <li>Best Broadcast Network or Cable Comedy Special</li>
          <li>Best Broadcast Network Series, Drama</li>
          <li>Best Cable Series, Drama</li>
          <li>Best Actor in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Actress in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Supporting Actor in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Supporting Actress in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Writing in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Directing in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Broadcast Network Series, Comedy</li>
          <li>Best Cable Series, Comedy</li>
          <li>Best Actor in a Broadcast Network or Cable Series, Comedy</li>
          <li>Best Actress in a Broadcast Network or Cable Series, Comedy</li>
          <li>Best Supporting Actor in a Broadcast Network or Cable Series, Comedy</li>
          <li>Best Supporting Actress in a Broadcast Network or Cable Series, Comedy</li>
          <li>Best Writing in a Broadcast Network or Cables Series, Comedy</li>
          <li>Best Directing in a Broadcast Network or Cable Series, Comedy</li>
          <li>Best Broadcast Network or Cable Limited or Anthology Series</li>
          <li>Best Broadcast Network or Cable Live-Action Television Movie</li>
          <li>Best Actor in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
          <li>Best Actress in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
          <li>Best Supporting Actor in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
          <li>Best Supporting Actress in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
          <li>Best Writing in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
          <li>Best Directing in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
        </ul>
        
        <h3 className='mt-5 mb-3'>2024 HCA TV Awards Streaming Ceremony Categories:</h3>
        <ul className='list-unstyled'>
          <li>Best Streaming Animated Series or TV Movie</li>
          <li>Best Streaming Comedy Special</li>
          <li>Best Streaming Reality Show or Competition Series</li>
          <li>Best Streaming Series, Drama</li>
          <li>Best Actor in a Streaming Series, Drama</li>
          <li>Best Actress in a Streaming Series, Drama</li>
          <li>Best Supporting Actor in a Streaming Series, Drama</li>
          <li>Best Supporting Actress in a Streaming Series, Drama</li>
          <li>Best Writing in a Streaming Series, Drama</li>
          <li>Best Directing in a Streaming Series, Drama</li>
          <li>Best Streaming Series, Comedy</li>
          <li>Best Actor in a Streaming Series, Comedy</li>
          <li>Best Actress in a Streaming Series, Comedy</li>
          <li>Best Supporting Actor in a Streaming Series, Comedy</li>
          <li>Best Supporting Actress in a Streaming Series, Comedy</li>
          <li>Best Writing in a Streaming Series, Comedy</li>
          <li>Best Directing in a Streaming Series, Comedy</li>
          <li>Best Streaming Limited or Anthology Series</li>
          <li>Best Streaming Movie</li>
          <li>Best Actor in a Streaming Limited or Anthology Series or Movie</li>
          <li>Best Actress in a Streaming Limited or Anthology Series or Movie</li>
          <li>Best Supporting Actor in a Streaming Limited or Anthology Series or Movie</li>
          <li>Best Supporting Actress in a Streaming Limited or Anthology Series or Movie</li>
          <li>Best Writing in a Streaming Limited or Anthology Series or Movie</li>
          <li>Best Directing in a Streaming Limited or Anthology Series or Movie</li>
        </ul>

        <h3 className='mt-5 mb-3'>Nominations Info:</h3>
        <p>The number of nominations will be based on the submissions received in each category. There can be a minimum of six nominees in each category and a maximum of ten.</p>

        <h3 className='mt-5 mb-3'>Streaming Platform Submission Info:</h3>
        <p>To submit as a series or movie, it must be original programming exclusive to the streaming platform. This content should not be available anywhere else.</p>

        <p>For example, shows that air on FX but are available the next day on Hulu do not qualify as streaming titles. Current streaming platforms that are eligible for nominations (more to be added as original/exclusive content is created on other streaming platforms):</p>

        <ul className='list-unstyled'>
          <li>AcornTV</li>
          <li>Allblk</li>
          <li>Amazon Prime</li>
          <li>AMC+</li>
          <li>Apple TV +</li>
          <li>BET+</li>
          <li>Crunchyroll</li>
          <li>Discovery +</li>
          <li>Disney +</li>
          <li>ESPN +</li>
          <li>Freevee</li>
          <li>HBO Max</li>
          <li>Hulu</li>
          <li>MGM+</li>
          <li>Netflix</li>
          <li>Paramount +</li>
          <li>Peacock</li>
          <li>Roku</li>
          <li>Shudder</li>
          <li>SundanceNow</li>
          <li>We TV+</li>
        </ul> */}

        <h3 className='mt-5 mb-3'>Submission Checklist:</h3>
        <ul>
          <li>Basic information about the selected entry.</li>
          <li>A brief plot description of the content being submitted.</li>
          <li>A promotional image from the series or movie. Headshots for any talent submissions.</li>
          <li>One video submission of the movie or tv show for consideration</li>
          <li>Ways to submit videos:
            <ul>
              <li>Provide a digital link to access the screener.</li>
              <li>Provide an FYC app or website with all required login information.</li>
              <li>Video Quality: 720p, 1080p, or 4K.</li>
              <li>Video Format: MP4 or MOV.</li>
              <li>Audio Codec: Stereo or 5.1 Sound.</li>
              <li>The file size should be under 35GB.</li>
              <li>All video submissions should include beginning and end credits.</li>
              <li>Closed Captioning is preferred.</li>
              <li>If digital screeners are not yet available, nominating committee members can receive invites to attend in-person screenings.</li>
            </ul>
          </li>
        </ul>

        <p className='mt-5'>Questions? Please email <a className='guidelines--link' href='mailto:submissions@TheAstras.com'>submissions@TheAstras.com</a>.</p>
      </Col>
    </Row>
  </Layout>
);

export default SubmissionGuidelinesPage